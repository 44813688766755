import Vue from 'vue'
import Antd from 'ant-design-vue';
import axios from 'axios'
import App from './App.vue'
import router from './router'
import store from './store'
import Qs from 'qs'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
Vue.prototype.$ajax = axios
Vue.prototype.Qs = Qs
import 'ant-design-vue/dist/antd.css';
//https://eval.t.educg.com'

Vue.prototype.url = process.env.VUE_APP_URL
Vue.config.productionTip = false
Vue.use(Antd).use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

